"use client";

import Link from "next/link";
import Container from "./container";

export default function Footer() {
  const resources = [
    { name: "Interview Questions By Company", href: "/get-started" },
    { name: "Interview Guides", href: "/guides" },
    { name: "1point3acres Content", href: "/1p3a" },
    { name: "Leetcode Tagged Questions", href: "/leetcode-tagged" },
  ];

  const company = [
    { name: "About", href: "/#about" },
    { name: "Features", href: "/#features" },
    { name: "Testimonials", href: "/#testimonials" },
  ];

  return (
    <div className="relative">
      <Container>
        <div className="grid max-w-screen-xl grid-cols-1 gap-10 pt-10 mx-auto mt-5 border-t border-gray-100 dark:border-trueGray-700 lg:grid-cols-3">
          {/* Resources Column */}
          <div>
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Interview Resources
            </h3>
            <div className="flex flex-col mt-4 space-y-4">
              {resources.map((item) => (
                <Link
                  key={item.name}
                  href={item.href}
                  className="text-base text-gray-600 dark:text-gray-300 hover:text-indigo-500 hover:underline"
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>

          {/* Company Column */}
          <div>
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Company
            </h3>
            <div className="flex flex-col mt-4 space-y-4">
              {company.map((item) => (
                <Link
                  key={item.name}
                  href={item.href}
                  className="text-base text-gray-600 dark:text-gray-300 hover:text-indigo-500 hover:underline"
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>

          {/* About Column */}
          <div>
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              About InterviewDB
            </h3>
            <p className="mt-4 text-base text-gray-600 dark:text-gray-300">
              A database of company-specific interview questions — like LeetCode tagged, but on steroids!
            </p>
          </div>
        </div>

        <div className="my-10 text-sm text-center text-gray-600 dark:text-gray-400">
          Copyright © {new Date().getFullYear()} InterviewDB.
          Victory is reserved for those who are willing to pay its price.
        </div>
      </Container>
    </div>
  );
}
