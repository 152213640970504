"use client";

import { useState, useEffect } from 'react';
import Link from "next/link";
import ThemeChanger from "./DarkSwitch";
import Image from "next/image"
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ChevronDownIcon } from '@heroicons/react/20/solid'

const Navbar = ({ navigation = ["Product", "Features", "Testimonials"], session,
  includeLogInProfileButton = true,
  showPointsBalance = false }) => {

  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const currentDomain = window.location.hostname;
    const newDomain = 'www.interviewdb.io';
    if (currentDomain !== newDomain) {
      setShowBanner(true);
    }
  }, []);


  const dropdownItems = [
    { name: "Interview Questions By Company", href: "/get-started" },
    { name: "Interview Guides", href: "/guides" },
    { name: "1point3acres Content Request", href: "/1p3a" },
    { name: "Leetcode Tagged Questions Request", href: "/leetcode-tagged" },
  ];

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className="w-full">
      {showBanner && (
        <div className="w-full bg-yellow-100 p-4 text-center">
          <p className="text-yellow-800">
            {"We've moved! Visit us at "}
            <Link href="https://interviewdb.io" className="font-bold text-yellow-900 underline">
              {"interviewdb.io"}
            </Link>
            {" to avoid sign in issues."}
          </p>
        </div>
      )}
      <nav className="container relative flex flex-wrap items-center justify-between p-8 mx-auto lg:justify-between xl:px-0" aria-label="Main navigation">
        <Disclosure as="div" className="flex flex-wrap items-center justify-between w-full lg:w-auto">
          {({ open }) => (
            <>
              <div className="flex items-center">
                <Link href="/" className="flex items-center space-x-2 text-2xl font-medium text-indigo-500 dark:text-gray-100">
                  <Image
                    src="/img/logo.svg"
                    alt="InterviewDB logo"
                    width="32"
                    height="32"
                    className="w-8"
                  />
                  <span>InterviewDB</span>
                </Link>

                <Disclosure.Button
                  aria-label={open ? "Close menu" : "Open menu"}
                  className="px-2 py-1 ml-auto text-gray-500 rounded-md lg:hidden hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none dark:text-gray-300 dark:focus:bg-trueGray-700">
                  <svg
                    className="w-6 h-6 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24">
                    {open ? (
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                      />
                    ) : (
                      <path
                        fillRule="evenodd"
                        d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                      />
                    )}
                  </svg>
                </Disclosure.Button>
              </div>

              <Disclosure.Panel className="flex flex-wrap w-full my-5 lg:hidden">
                <NavMenu dropdownItems={dropdownItems} navigation={navigation} scrollToSection={scrollToSection} isMobile={true} />
                <Link href="/" className="w-full px-6 py-2 mt-3 text-center text-white bg-indigo-600 rounded-md lg:ml-5">
                  Get Started
                </Link>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <div className="hidden text-center lg:flex lg:items-center">
          <NavMenu dropdownItems={dropdownItems} navigation={navigation} scrollToSection={scrollToSection} isMobile={false} />
        </div>

        {includeLogInProfileButton && (
          <div className="hidden mr-3 space-x-4 lg:flex nav__item">
            {!session ? (
              <Link href="/login" className="px-6 py-2 text-white bg-indigo-600 rounded-md md:ml-5 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                Log In
              </Link>
            ) : (
              <Link href="/home" className="px-6 py-2 text-white bg-indigo-600 rounded-md md:ml-5 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                Profile
              </Link>
            )}
          </div>
        )}
      </nav>
    </header>
  );
}


const NavMenu = ({ dropdownItems, navigation, scrollToSection, isMobile }) => {
  const MenuWrapper = isMobile ? 'div' : 'ul';
  const MenuItem = isMobile ? 'div' : 'li';

  return (
    <MenuWrapper className={isMobile ? "w-full" : "items-center justify-end flex-1 pt-6 list-none lg:pt-0 lg:flex"}>
      <MenuItem className={isMobile ? "w-full" : "mr-3 nav__item"}>
        <Menu as="div" className={isMobile ? "relative w-full" : "relative inline-block text-left"}>
          <Menu.Button className={`${isMobile
            ? "w-full px-4 py-2 -ml-4 text-left text-gray-800 rounded-md dark:text-gray-200 hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 dark:focus:bg-gray-800 focus:outline-none flex items-center justify-between"
            : "inline-flex items-center px-4 py-2 text-lg font-normal text-gray-800 no-underline rounded-md dark:text-gray-200 hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none dark:focus:bg-gray-800"
            }`}>
            Interview Resources
            <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1 text-indigo-500" aria-hidden="true" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className={`${isMobile
              ? "w-full mt-2 origin-top-right bg-white dark:bg-gray-800 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden z-50"
              : "absolute left-0 w-56 mt-2 origin-top-right bg-white dark:bg-gray-800 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden z-50"
              }`}>
              {dropdownItems.map((item, index) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <Link
                      href={item.href}
                      className={`${active ? 'bg-indigo-100 text-indigo-900 dark:bg-indigo-700 dark:text-indigo-100' : 'text-gray-700 dark:text-gray-300'
                        } block px-4 py-2 text-sm ${index === 0 ? 'rounded-t-md' : ''} ${index === dropdownItems.length - 1 ? 'rounded-b-md' : ''}`}
                    >
                      {item.name}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      </MenuItem>

      {navigation.map((menu, index) => (
        <MenuItem key={index} className={isMobile ? "w-full" : "mr-3 nav__item"}>
          <Link
            href={`/#${menu.toLowerCase()}`}
            onClick={(e) => {
              e.preventDefault();
              scrollToSection(menu.toLowerCase());
            }}
            className={`${isMobile
              ? "w-full px-4 py-2 -ml-4 text-left text-gray-800 rounded-md dark:text-gray-200 hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 dark:focus:bg-gray-800 focus:outline-none"
              : "inline-block px-4 py-2 text-lg font-normal text-gray-800 no-underline rounded-md dark:text-gray-200 hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none dark:focus:bg-gray-800"
              }`}
          >
            {menu}
          </Link>
        </MenuItem>
      ))}
    </MenuWrapper>
  );
}

export default Navbar;