"use client";

import React from "react";
import Container from "./container";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";

// Helper function to convert text with URLs to React elements
const convertTextToElements = (text) => {
  if (!text) return null;
  
  // Split text by newlines to handle bullet points
  const lines = text.split('\n');
  
  return lines.map((line, lineIndex) => {
    // Updated regex to avoid capturing trailing punctuation
    const urlRegex = /(https?:\/\/[^\s)\]}"']+)/g;
    
    // If no URLs in this line, return just the text
    if (!urlRegex.test(line)) {
      return <React.Fragment key={lineIndex}>{line}<br/></React.Fragment>;
    }

    // Split the line into parts (text and URLs)
    const parts = line.split(urlRegex);
    const matches = line.match(urlRegex) || [];
    
    return (
      <React.Fragment key={lineIndex}>
        {parts.map((part, index) => {
          // If this part matches a URL, render it as a link
          if (matches.includes(part)) {
            return (
              <a
                key={index}
                href={part}
                target="_blank"
                rel="noopener noreferrer"
                className="text-indigo-600 hover:text-indigo-800 dark:text-indigo-400 dark:hover:text-indigo-300"
              >
                {part}
              </a>
            );
          }
          // Otherwise render as plain text
          return part;
        })}
        <br/>
      </React.Fragment>
    );
  });
};

const Faq = () => {
  return (
    <Container className="!p-0">
      <div className="w-full max-w-2xl p-2 mx-auto rounded-2xl">
        {faqdata.map((item) => (
          <div key={item.question} className="mb-5">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex items-center justify-between w-full px-4 py-4 text-lg text-left text-gray-800 rounded-lg bg-gray-50 hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-indigo-100 focus-visible:ring-opacity-75 dark:bg-trueGray-800 dark:text-gray-200">
                    <span>{item.question}</span>
                    <ChevronUpIcon
                      className={`${
                        open ? "" : "transform rotate-180"
                      } w-5 h-5 text-indigo-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-gray-500 dark:text-gray-300">
                    {convertTextToElements(item.answer)}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        ))}
      </div>
    </Container>
  );
};

const faqdata = [
  {
    question: "Are there any subscription fees or costs to use InterviewDB?",
    answer: `InterviewDB uses points to manage access to interview questions. Here's how it works:
• Many questions are completely free to access
• Premium questions can be unlocked with points
• Earn points by contributing questions to help the community
• Or purchase points directly if you prefer

No subscriptions or recurring fees - you only pay if you choose to buy points.`
  },
  {
    question: "Where does InterviewDB source all the interview questions from?",
    answer: `We source interview questions from community contribution as well as various forums and discussion boards like 1point3acres and LeetCode Discuss, which we monitor on a daily basis.`,
  },
  {
    question: "How often is the question database updated on InterviewDB?",
    answer: `Our question database is updated regularly as we continuously source new interview questions from the community and online forums. We strive to reflect the latest interviewing trends and standards in the tech industry.`
  },
  {
    question: "Can I contribute to the question database if I have recently taken a tech interview?",
    answer: `We welcome contributions from community members! If you have recently taken a tech interview, you can submit questions through our contribution form (https://forms.gle/E6t1MXHgeymXfmjA9). After a review for accuracy and relevance, you will be awarded points. Your questions can help countless others in their interview preparation.`
  },
];

export default Faq;